<template>
  <div class="view pa24">
    <p class="cA1 fwbold mb10">提现设置</p>
    <div class="d-flex mb20 bgf7fa flex-column hover_pointer pl30 pt20 pb20">
      <div class="d-flex align-items-center fs9 mb20">
        <p>1、提现最低标准设置：企业余额最低提现金额为</p>
        <el-input class="ml10 mr10 w100" v-model="staffs.singleLimit" type="number"></el-input>
        <p>元。(0表示不限制)</p>
      </div>
      <div class="d-flex align-items-center fs9">
        <p>2、打款方式设置：是否开启自动打款</p>
        <el-switch v-model="staffs.autoArrive" active-color="#51CBCD">
        </el-switch>
        <p>（开启自动打款，即表示企业余额提现时，无需审核，提现金额自动到达企业BOSS微信账户上）</p>
      </div>
      <div class="flex-c-c mt20">
        <el-button type="primary" @click="saveSetting">保存</el-button>
      </div>
    </div>
    <p class="cA1 fwbold mb10">提现列表</p>
    <div class="d-flex align-items-center">
      <p>企业名称：</p>
      <el-input class="ml10 mr10 w200" type="text" v-model="companyName" placeholder="请输入企业名称"></el-input>
      <div class=""></div>
      提现时间：
      <el-date-picker v-model="pickerValue" class="mr10 " align="right" value-format="yyyy-MM-dd" type="daterange"
        start-placeholder="开始日期" end-placeholder="结束日期" />
      <div v-for="(item, index) in dateList" @click="currentDateIndex = item.date" :key="index"
        class="flex-c-c w80 h34 mr10 br5 hover_pointer"
        :class="currentDateIndex == item.date ? 'bbule1 cblue trans2' : 'bDCDFE6 trans2'">
        {{ item.name }}
      </div>
      <el-button type="primary" @click="searchFun">搜索</el-button>
      <!-- <el-button type="primary" @click="queryVisitorsData">导出</el-button> -->
    </div>

    <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
      <template v-slot:table>

        <el-table-column prop="recordId" align="center" label="编号" show-overflow-tooltip />
        <el-table-column prop="companyName" align="center" label="企业名称" />
        <el-table-column prop="name" align="center" label="收款账户" />
        <el-table-column prop="amount" align="center" label="提现金额（元）">
          <template slot-scope="scope">
            {{ scope.row.amount ? scope.row.amount : '-' }}
          </template>
        </el-table-column>
        <el-table-column prop="createdTime" align="center" label="申请时间" />
        <el-table-column prop="sumNum" align="center" label="状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.approvalStatus == 0">等待打款</el-tag>
            <el-tag v-if="scope.row.approvalStatus == 1">打款成功</el-tag>
            <el-tag v-if="scope.row.approvalStatus == 2" type="danger">已驳回</el-tag>
            <el-tag v-if="scope.row.approvalStatus == 3">审核通过待打款</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="approvalTime" align="center" label="打款/驳回时间" />
        <el-table-column prop="note" align="center" label="备注" />
        <el-table-column prop="forwardfNum" align="center" label="操作">
          <template slot-scope="scope">
            <div class="d-flex justify-content-center" v-if="scope.row.approvalStatus == 0">
              <div @click="openConfirmBox(scope.row.recordId)" class="cblue hover_pointer mr10">
                打款
              </div>
              <div @click="openRejectBox(scope.row.recordId)" class="corange hover_pointer">
                驳回
              </div>
            </div>
            <div class="d-flex justify-content-center" v-else>
              -
            </div>

          </template>
        </el-table-column>

      </template>
    </commonTable>
    <!-- <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="余额提现" 
               width="40%"
               :center="true"
               
               :visible="dialogTableVisible"
               @close="dialogClose">
        <div class="pl20">
          <div class="flex-a-c  mb20">
            <div class="w200 text-align-right mr20 cA1 fs8 fwbold">
              收款账户：
            </div>
            <div class="flex-a-c">
              <p class="cA1 fs8 fwbold mr40">半仙</p>
              <p class="fs7">提现账户默认为企业BOSS账户，不可更改</p>
            </div>
          </div>

          <div class="flex-a-c mb20">
            <div class="w200 text-align-right mr20 cA1 fs8 fwbold">
              提现金额：
            </div>
            <div class="flex-a-c">
              <el-input class="mr20" v-model="input" placeholder="请输入提现金额"></el-input>
              <el-button type="primary" @click="cancel">
                全部提现
              </el-button>
            </div>
          </div>
          <div class="flex-a-c mb20">
            <div class="w200 text-align-right mr20 cA1 fs8 fwbold">
              最低提现金额：
            </div>
            <div class="flex-a-c">
              <p class="cA1 fs8 fwbold mr40">￥0.01</p>
            </div>
          </div>
          <div class="flex-a-c mb20">
            <div class="w200 text-align-right mr20 cA1 fs8 fwbold">
              当前可提现金额
            </div>
            <div class="flex-a-c">
              <p class="cA1 fs8 fwbold mr40">￥1852.55</p>
            </div>
          </div>
        </div>

        <div class="mt40 flex-c-c">
          <el-button type="primary" plain @click="cancel">
            取消
          </el-button>
          <el-button type="primary" @click="onConfirmShop">
            确认
          </el-button>
        </div>
    </el-dialog> -->

  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { getDataTimeSec } from "@/utils";
import { queryBossWithdrawalSetting, updateBossWithdrawalSetting, queryBossWithdrawals, bossWithdrawalsReview } from "@/api/financing";
export default {
  name: "visitorData",
  components: {
    commonTable,
  },
  data() {
    return {
      pickerValue: "", //选择时间
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      staffs: {
        autoArrive: false,
        singleLimit: 0,
      },
      userId: "",
      sourcesId: "",
      dateList: [
        { id: 1, name: "全部", date: 4 },
        { id: 2, name: "等待打款", date: 0 },
        { id: 3, name: "打款成功", date: 1 },
        { id: 4, name: "已驳回", date: 2 },
      ],
      authsId: "",
      phone: "",
      currentDateIndex: 4,
      automatic: false,
      companyName: '', //公司名
    };
  },
  filters: {
    getDataTimeSec(val) {
      return getDataTimeSec(val);
    },
  },
  created() {
    this.queryCompanyStaff();
    this.queryVisitorsData();
  },
  methods: {
    async queryCompanyStaff() {
      try {
        const result = await queryBossWithdrawalSetting({ Id: '' });
        //-console.log(result)
        if (result.data) {
          this.staffs = result.data;
        }
      } catch (error) {
        //-console.log(error);
      }
    },
    // 保存提现设置
    async saveSetting() {
      let data = {
        autoArrive: this.staffs.autoArrive,
        singleLimit: this.staffs.singleLimit
      }
      const result = await updateBossWithdrawalSetting(data);
      //-console.log(result)
      if (result.code == 200) {
        this.$message.success(result.message)
      } else {
        this.$message.error(result.message)
      }
    },
    searchFun() {
      this.currentPage = 1
      this.queryVisitorsData();
    },
    async queryVisitorsData() {
      let usesrInfo = JSON.parse(localStorage.getItem('info'))

      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        approvalStatus: this.currentDateIndex,
        mainCompanyId: usesrInfo.roleId,
        companyName: this.companyName,
        startTime: this.pickerValue && `${this.pickerValue[0]} 00:00:00`,
        endTime: this.pickerValue && `${this.pickerValue[1]} 23:59:59`,
        type: 1
      };
      if (this.currentDateIndex == 4) {
        data.approvalStatus = ''
      }
      this.loading = true;
      queryBossWithdrawals(data)
        .then(res => {
          this.loading = false;
          this.tableData = res?.data?.pageInfo.list;
          this.total = res?.data?.pageInfo.total
        })
        .catch(err => {
          this.tableData = []
          this.loading = false;
          //-console.log(err);
        })
    },
    formatSeconds(value) {
      var theTime = parseInt(value);// 秒
      var theTime1 = 0;// 分
      var theTime2 = 0;// 小时
      if (theTime > 60) {
        theTime1 = parseInt(theTime / 60);
        theTime = parseInt(theTime % 60);
        if (theTime1 > 60) {
          theTime2 = parseInt(theTime1 / 60);
          theTime1 = parseInt(theTime1 % 60);
        }
      }
      var result = "" + parseInt(theTime) + "秒";
      if (theTime1 > 0) {
        result = "" + parseInt(theTime1) + "分" + result;
      }
      if (theTime2 > 0) {
        result = "" + parseInt(theTime2) + "小时" + result;
      }
      return result;
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
      this.queryVisitorsData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryVisitorsData();
    },
    dialogClose() {
      this.dialogTableVisible = false
    },
    cancel() {
      this.dialogTableVisible = false
    },
    onConfirmShop() {

    },
    openRejectBox(id) {
      this.$prompt('请输入驳回理由', '驳回', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /\S/,
        inputErrorMessage: '驳回理由不能为空'
      }).then(({ value }) => {
        let data = {
          recordId: id,
          approvalStatus: 2,
          note: value
        }
        this.bossWithdrawalsReview(data)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    openConfirmBox(id) {
      this.$confirm('此操作不可逆，确认继续打款', '打款', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        let data = {
          recordId: id,
          approvalStatus: 3,
        }
        this.bossWithdrawalsReview(data)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    bossWithdrawalsReview(data) {
      bossWithdrawalsReview(data)
        .then(res => {
          if (res.code == 200) {
            this.$message.success(res.message)
          } else {
            this.$message.error(res.message)
          }
          this.queryVisitorsData();
        })
        .catch(err => {
          this.$message.error(res.message)
        })
    }
  },
};
</script>

<style lang="scss" scoped></style>