<template>
  <div>
    <el-tabs class="tabs" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="账户管理" name="first">
        <accountTab v-if="activeName == 'first'" />
      </el-tab-pane>

      <el-tab-pane label="提现列表" name="second">
        <withdrawalTab v-if="activeName == 'second'"/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import accountTab from "./components/accountTab.vue";
import withdrawalTab from "./components/withdrawalTab.vue";
import { mapState } from 'vuex';

export default {
  name: "management",
  components: {
    accountTab,
    withdrawalTab,
  },

  data() {
    return {
      activeName: "first",
      jurisdiction:false,
    };
  },
  // computed: {
  //   ...mapState(['routerArr']),
    
  // },
  // created(){
  //   if(this.$route.query.name){
  //     this.activeName = this.$route.query.name
  //   }
  // },
  // watch:{
  //   routerArr(val){
  //     this.jurisdiction = val.includes("040")
  //     //-console.log(this.jurisdiction,'this.jurisdiction')
  //   },
  // },
  methods: {
    handleClick(tab) {
      const { name } = tab;
      this.activeName = name;
    },
  },
};
</script>

<style lang="scss" scoped></style>
